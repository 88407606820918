.preisemenge { width: 70px; text-align: right;}

ion-note { align-self: center!important}

input[type=text] { height: 28px;  width: 30px; margin-right: 4px; border-radius: 4px; border: none; color: black; text-align: center; font-size: 20px}
.datum {margin: 15px}
.centered { justify-content: center; text-align: center}

.alignit { display: flex;  align-items: end}
.right { justify-content: end; }
.left { justify-content: start; }

.aligncenter { display: flex;  align-items: center }
.orderbutton { text-transform: uppercase;}